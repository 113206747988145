@charset "utf-8";

/* ------------------------------------------------------------------------------------------------------------- */
/* reset */

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym,
address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var,
b, u, i, center, button, img, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video
{margin:0; padding:0; border:none; font-size:100%; vertical-align:baseline;}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display:block;}

ol, ul, li {list-style:none;}

blockquote, q {quotes:none;}
blockquote:before, blockquote:after, q:before, q:after { content:''; content:none;}

table, tr, th, td {vertical-align:middle;}
table {border-collapse:collapse; border-spacing:0; border:0; table-layout:fixed;}

a {color:inherit; text-decoration:none;}
a:hover {color:inherit; text-decoration:none;}

caption, legend {display:none;}

em {font-style: normal;}
b,strong {font-weight:normal;}

button {margin:0; padding:0; color:none; border:none; outline:none; background:none; cursor:pointer}

img, div, button {
	-webkit-tap-highlight-color:rgba(0,0,0,0);
}

img {pointer-events: none;}

img, div, button, p {
	-webkit-touch-callout:none; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; -webkit-tap-highlight-color:rgba(0,0,0,0);
}

button span {pointer-events: none;}

* {
	margin:0; padding:0; border:none; -moz-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box;
}
