@charset "utf-8";

/* noto-sans-kr-300 - latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/noto-sans-kr-v12-korean_latin-300.eot');
  src: local(''),
    url('../fonts/noto-sans-kr-v12-korean_latin-300.eot?#iefix') format('embedded-opentype'),
    url('../fonts/noto-sans-kr-v12-korean_latin-300.woff2') format('woff2'),
    url('../fonts/noto-sans-kr-v12-korean_latin-300.woff') format('woff'),
    url('../fonts/noto-sans-kr-v12-korean_latin-300.ttf') format('truetype'),
    url('../fonts/noto-sans-kr-v12-korean_latin-300.svg#NotoSansKR') format('svg');
}

/* noto-sans-kr-regular - latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/noto-sans-kr-v12-korean_latin-regular.eot');
  src: local(''),
    url('../fonts/noto-sans-kr-v12-korean_latin-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/noto-sans-kr-v12-korean_latin-regular.woff2') format('woff2'),
    url('../fonts/noto-sans-kr-v12-korean_latin-regular.woff') format('woff'),
    url('../fonts/noto-sans-kr-v12-korean_latin-regular.ttf') format('truetype'),
    url('../fonts/noto-sans-kr-v12-korean_latin-regular.svg#NotoSansKR') format('svg');
}

/* noto-sans-kr-500 - latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/noto-sans-kr-v12-korean_latin-500.eot');
  src: local(''),
    url('../fonts/noto-sans-kr-v12-korean_latin-500.eot?#iefix') format('embedded-opentype'),
    url('../fonts/noto-sans-kr-v12-korean_latin-500.woff2') format('woff2'),
    url('../fonts/noto-sans-kr-v12-korean_latin-500.woff') format('woff'),
    url('../fonts/noto-sans-kr-v12-korean_latin-500.ttf') format('truetype'),
    url('../fonts/noto-sans-kr-v12-korean_latin-500.svg#NotoSansKR') format('svg');
}

/* noto-sans-kr-700 - latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/noto-sans-kr-v12-korean_latin-700.eot');
  src: local(''),
    url('../fonts/noto-sans-kr-v12-korean_latin-700.eot?#iefix') format('embedded-opentype'),
    url('../fonts/noto-sans-kr-v12-korean_latin-700.woff2') format('woff2'),
    url('../fonts/noto-sans-kr-v12-korean_latin-700.woff') format('woff'),
    url('../fonts/noto-sans-kr-v12-korean_latin-700.ttf') format('truetype'),
    url('../fonts/noto-sans-kr-v12-korean_latin-700.svg#NotoSansKR') format('svg');
}


/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/montserrat-v14-latin-300.eot');
  src: local(''),
    url('../fonts/montserrat-v14-latin-300.eot?#iefix') format('embedded-opentype'),
    url('../fonts/montserrat-v14-latin-300.woff2') format('woff2'),
    url('../fonts/montserrat-v14-latin-300.woff') format('woff'),
    url('../fonts/montserrat-v14-latin-300.ttf') format('truetype'),
    url('../fonts/montserrat-v14-latin-300.svg#Montserrat') format('svg');
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v14-latin-regular.eot');
  src: local(''),
    url('../fonts/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/montserrat-v14-latin-regular.woff2') format('woff2'),
    url('../fonts/montserrat-v14-latin-regular.woff') format('woff'),
    url('../fonts/montserrat-v14-latin-regular.ttf') format('truetype'),
    url('../fonts/montserrat-v14-latin-regular.svg#Montserrat') format('svg');
}

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat-v14-latin-500.eot');
  src: local(''),
    url('../fonts/montserrat-v14-latin-500.eot?#iefix') format('embedded-opentype'),
    url('../fonts/montserrat-v14-latin-500.woff2') format('woff2'),
    url('../fonts/montserrat-v14-latin-500.woff') format('woff'),
    url('../fonts/montserrat-v14-latin-500.ttf') format('truetype'),
    url('../fonts/montserrat-v14-latin-500.svg#Montserrat') format('svg');
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/montserrat-v14-latin-700.eot');
  src: local(''),
    url('../fonts/montserrat-v14-latin-700.eot?#iefix') format('embedded-opentype'),
    url('../fonts/montserrat-v14-latin-700.woff2') format('woff2'),
    url('../fonts/montserrat-v14-latin-700.woff') format('woff'),
    url('../fonts/montserrat-v14-latin-700.ttf') format('truetype'),
    url('../fonts/montserrat-v14-latin-700.svg#Montserrat') format('svg');
}