@charset "utf-8";

/* ------------------------------------------------------------------------------------------------------------- */
/* display */
.show {
  display: block !important;
}

.hide,
.show.hide {
  display: none !important;
}

.blind {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  margin: -1px;
  text-indent: -9999px;
  overflow: hidden;
}

.w100 {
  width: 100%;
}

.w50 {
  float: left;
  width: 50%;
}

.w30 {
  float: left;
  width: 30%;
}

.w70 {
  float: left;
  width: 70%;
}

.wbox {
  background-color: #ffffff;
}

.bline {
  border-bottom: 1px solid #EEEEEE;
}

.row {
  display: inline-block;
  width: 100%;
}

.item_list>li {
  float: left;
}

.item_list:after,
.item_list>li:last-child:after {
  content: '';
  display: block;
  clear: both;
}

.fl {
  float: left;
}

.fl:last-child:after {
  content: '';
  display: block;
  clear: both;
}

.cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.row {
  display: inline-block;
  width: 100%;
}

.flex {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  box-sizing: border-box;
}

.flex.row {
  -webkit-flex-direction: row;
  flex-direction: row;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
}

.flex.column {
  -webkit-flex-direction: column;
  flex-direction: column;
}

.flex.center {
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.flex.around {
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.flex.between {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.inlineFlex {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.blockFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ------------------------------------------------------------------------------------------------------------- */

.mb60 {
  margin-bottom: 60px;
}