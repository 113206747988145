@charset "utf-8";

/* ------------------------------------------------------------------------------------------------------------- */
/* wrap */
html,
body {
  width: 100%;
  height: 100%;
  background-color: #777777;
  position: relative;
  margin: 0 auto;
}

#root {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

#wrap {
  position: fixed;
  width: 100%;
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
  background-color: #FAFAFA;
  font-family: 'Montserrat', 'Noto Sans KR', sans-serif, serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  overflow: hidden;
  word-break: keep-all;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* header */
.header {
  position: relative;
  width: 100%;
  min-height: 80px;
  background-color: #FFFFFF;
  overflow: hidden;
}

.idx_main .header {
  background-color: #FAD84F;
}

.idx_main .header .logo_wrap {
  position: absolute;
  top: 45px;
  left: 15px;
}

.idx_main .header .svg_logo {
  width: 85px;
  height: 13px;
}

.idx_main .header .util_wrap {
  position: absolute;
  top: 28px;
  right: 5px;
  width: 44px;
  height: 44px;
  text-align: center;
}

@media (hover: hover) {
  .idx_main .header .util_wrap:hover {
    border-radius: 24px;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,.24),
                0 1px 4px   0 rgba(0,0,0,.48) !important;
  }
}

.idx_main .header .util_wrap [class*="svg_"] {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 10px;
  left: 10px;
}

.header .nav_wrap {
  position: absolute;
  top: 47px;
  left: 18px;
}

@media (hover: hover) {
  .header .nav_wrap:hover {
    position: absolute;
    top: 25px;
    padding-top: 22px;
    left: 10px;
    padding-left:8px;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,.24),
                0 1px 4px   0 rgba(0,0,0,.48) !important;
  }
}

.header .nav_wrap .svg_back {
  width: 20px;
  height: 10px;
}


.header .title {
  position: absolute;
  top: 40px;
  left: 72px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.header .title.max {
  position: absolute;
  top: 40px;
  left: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* content */
.container {
  position: relative;
  width: 100%;
  height: calc(100% - 136px);
  overflow: hidden;
  overflow-y: auto;
}
.container.no_navigation {
  height: calc(100% - 80px);
}

.container.container_btn_wrap {
  height: calc(100% - 192px);
}

/* ------------------------------------------------------------------------------------------------------------- */
/* content - main banner */
.container .vis_wrap {
  width: 100%;
  padding-bottom: 24px;
  background-color: #FAD84F;
  text-align: center;
}

.container .vis_wrap_no_btn {
  width: 100%;
  background-color: #FAD84F;
  text-align: center;
}

.container .txt_vis {
  position: relative;
  height: fit-content;
}

.container .txt_vis img {
  position: relative;
  display: block;
  width: 100%;
}

.container .txt_vis .main_vis_txt {
  position: absolute;
  left: 5%;
  right: 0%;
  bottom: 0%;
  text-align: left;
  width: 100%;
  display: block;
}

.container .txt_vis .main_vis_txt {
  top: 16%;
  font-size: 1.3em;
  line-height: 1.5em;
}

@media (min-width: 300px) {
  .container .txt_vis .main_vis_txt {
    top: 16%;
    font-size: 1.4em;
    line-height: 1.7em;
  }
}

@media (min-width: 360px) {
  .container .txt_vis .main_vis_txt {
    top: 20%;
    font-size: 1.5em;
    line-height: 1.9em;
  }
}

@media (min-width: 480px) {
  .container .txt_vis .main_vis_txt {
    top: 20%;
    font-size: 2em;
    line-height: 2em;
  }
}

@media (min-width: 660px) {
  .container .txt_vis .main_vis_txt {
    top: 24%;
    font-size: 2.5em;
    line-height: 2em;
  }
}

.container .txt_vis .main_vis_txt_bold {
  font-weight: 700;
}

.container .vis_wrap .svg_img,
.container .vis_wrap_no_btn .svg_img {
  width: 100%;
}

.container .vis_btn {
  position: relative;
  display: block;
  width: 100%;
  height: 56px;
  background-color: #FCF0B4;
  border-radius: 4px;
  box-shadow: 0px 16px 30px rgba(86, 62, 2, 0.1);
}

.container .vis_btn:after {
  position: absolute;
  content: '';
  top: 50%;
  right: 18px;
  margin-top: -5px;
  width: 20px;
  height: 10px;
}

@media (hover: hover) {
  .container .vis_btn:hover {
    box-shadow: 0 1px 6px 0 rgba(0,0,0,.24),
                0 1px 4px   0 rgba(0,0,0,.48);
  }
}

.container .vis_wrap .btn_wrap,
.container .vis_wrap_no_btn .btn_wrap {
  width: calc(100% - 32px);
  margin: -10px auto 0px auto;
}

.container .vis_wrap .btn_wrap .svg_txt,
.container .vis_wrap_no_btn .btn_wrap .svg_txt {
  width: 100%;
  height: 56px;
  background-size: auto 100%;
  background-position: 16px 0px;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* content */
.container .content {
  width: 100%;
}

.container .content h1.tit {
  padding: 18px 16px 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  background-color: #FFFFFF;
}

.container .content h2.sbt {
  padding: 4px 16px 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background-color: #FFFFFF;
}

.idx_sub .container .content h1.tit,
.idx_sub .container .content h2.sbt {
  font-size: 18px;
}

.container .content .inner {
  background-color: #F8F8F8;
  position: relative;
  padding: 20px 16px;
}

.container>.btn_wrap {
  /* position: relative; */
  bottom: 56px;
  max-width: 800px;
  left: 0px;
  width: 100%;
}

.container>.btn_wrap.no_navigation {
  bottom: 0px;
}

.container>.btn_wrap .txt_btn {
  position: relative;
  height: 56px;
  line-height: 56px;
  max-width: 800px;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* tab */
.tab_container {
  display: block;
}

.tab_container .tab_menu {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 52px;
  background-color: #FFFFFF;
  overflow: hidden;
  overflow-x: auto;
}

.tab_container .tab_menu .tab {
  position: relative;
  flex-shrink: 0;
  font-weight: 400;
  line-height: 21px;
  color: #888888;
  padding: 19px 10px 12px 10px;
}

@media (min-width: 350px) {
  .tab_container .tab_menu.wordbook_tab_menu .tab {
    padding: 19px 16px 12px 16px;
  }
}

@media (min-width: 480px) {
  .tab_container .tab_menu.wordbook_tab_menu .tab {
    padding: 19px 26px 12px 26px;
  }
}

.tab_container .tab_menu .tab.on {
  font-weight: 500;
  color: #222222;
}

.tab_container .tab_menu .tab.on:before {
  position: absolute;
  content: '';
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background-color: #FAD84F;
}
@media (hover: hover) {
  .tab_container .tab_menu .tab:hover {
    margin-top: 1px;
    margin-bottom: 1px;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,.24),
                0 1px 4px   0 rgba(0,0,0,.48);
  }
}

.tab_container .tab_content [data-tab-list] {
  display: none;
}

.tab_container .tab_content [data-tab-list].on {
  display: block;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* round white box */
.rwbox {
  position: relative;
  display: block;
  margin-bottom: 8px;
  padding: 16px;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 16px 30px rgba(10, 55, 65, 0.08);
}

.rwbox .ex {
  position: relative;
  margin-top: 14px;
  padding: 15px 48px 0px 48px;
  border-top: 1px solid #EEEEEE;
  font-weight: 500;
  line-height: 24px;
  color: #888888;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.rwbox .ex>span.ico {
  position: absolute;
  left: 0px;
  top: 20px;
  width: 32px;
  height: 14px;
  background-color: #F8F8F8;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
  text-align: center;
  border-radius: 6px 6px 1px 6px;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* word_cover */
.word_cover .txt {
  padding-left: 48px;
  padding-right: 80px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.word_cover .number {
  padding-left: 48px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #888888;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.word_cover .state {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 64px;
  height: 22px;
  border-radius: 6px 6px 1px 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #666666;
}

.word_cover .state.complete {
  background-color: #F8F8F8;
}

.word_cover .state.ing {
  background-color: #e5eefe;
  color: #1B8CD7;
}

.word_cover .state.ready {
  background-color: #F8F8F8;
}

.word_cover .cover_icon {
  position: absolute;
  top: 16px;
  left: 14px;
  width: 40px;
  height: 40px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

@media (hover: hover) {
  .word_cover:hover {
  box-shadow: 0 1px 6px 0 rgba(0,0,0,.24),
              0 1px 4px   0 rgba(0,0,0,.48);
  }
}
/* ------------------------------------------------------------------------------------------------------------- */
/* level_cover */
.level_cover .level {
  position: relative;
  padding-top: 24px;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

.level_cover .level:before {
  position: absolute;
  top: 4px;
  left: 0px;
  content: '현재 레벨';
  width: 46px;
  height: 11px;
  font-size: 12px;
  font-weight: 400;
  color: #888888;
  text-indent: -9999px;
}

.level_cover .count {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 48px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  color: #FFFFFF;
  border-radius: 8px 8px 2px 8px;
  background: #fcd848;
  background: -moz-linear-gradient(116deg, #fcd848 16%, #ffc700 83%);
  background: -webkit-linear-gradient(116deg, #fcd848 16%, #ffc700 83%);
  background: linear-gradient(116deg, #fcd848 16%, #ffc700 83%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcd848', endColorstr='#ffc700', GradientType=1);
}

.level_cover .count.complete {
  background: #cccccc;
  background: -moz-linear-gradient(116deg, #cccccc 16%, #aaaaaa 83%);
  background: -webkit-linear-gradient(116deg, #cccccc 16%, #aaaaaa 83%);
  background: linear-gradient(116deg, #cccccc 16%, #aaaaaa 83%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cccccc', endColorstr='#aaaaaa', GradientType=1);
}

.level_cover .ex {
  margin-top: 0px;
  border-top: none;
}

.level_cover .btn_wrap {
  display: inline-block;
  margin-left: -16px;
  margin-top: 16px;
  margin-bottom: -20px;
  width: calc(100% + 32px);
  border-top: 1px solid #EEEEEE;
}

.level_cover .btn_wrap .txt_btn {
  height: 46px;
  line-height: 46px;
}
/* ------------------------------------------------------------------------------------------------------------- */
/* btn */
.btn_wrap.fix {
  position: fixed;
  bottom: 56px;
  left: 0px;
  width: 100%;
  max-width: 800px;
  height: 56px;
  left: 50%;
  transform: translateX(-50%);
}

.btn_wrap.fix .txt_btn {
  height: 100%;
}

.txt_btn {
  display: inline-block;
  font-family: 'Noto Sans KR';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #222222;
}

@media (hover: hover) {
  .txt_btn:hover {
    box-shadow: 0 1px 6px 0 rgba(0,0,0,.24),
                0 1px 4px   0 rgba(0,0,0,.48);
  }
}

.txt_btn.disable {
  background-color: #F8F8F8 !important;
  color: #888888 !important;
}

.txt_btn.w50+.txt_btn.w50 {
  border-left: 1px solid #EEEEEE;
}

@media (hover: hover) {
  .ripple:hover {
    box-shadow: 0 1px 6px 0 rgba(0,0,0,.24),
                0 1px 4px   0 rgba(0,0,0,.48) !important;
  }
}

.cbox {
  background-color: #FAD84F;
}

/* check */
.box_check {
  position: relative;
  width: 100%;
  height: 48px;
  padding: 0px 16px;
  background-color: #F8F8F8;
  border-radius: 4px;
  font-family: 'Noto Sans KR';
  line-height: 48px;
  text-align: left;
  color: #444444;
}

.box_check:after {
  position: absolute;
  content: '';
  top: 14px;
  right: 14px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #dddddd;
}

.box_check.on {
  background-color: #FAD84F;
  font-weight: 500;
  color: #222222;
}

.box_check.on:after {
  border: 1px solid #222222;
}

.box_check.min {
  width: auto;
  height: 32px;
  line-height: 32px;
  margin-right: 1px;
}

.box_check.min:after {
  display: none;
}

.disable .box_check,
.disable .box_check.on {
  background-color: #F8F8F8 !important;
  color: #888888 !important;
}

/* check toggle */
.move_check {
  position: relative;
  width: 60px;
  height: 32px;
  background-color: #FAD84F;
  border-radius: 36px;
  transition: all ease-out 0.2s;
}

.move_check:after {
  position: absolute;
  content: '';
  top: 2px;
  right: 2px;
  width: 28px;
  height: 28px;
  background-color: #FFFFFF;
  border-radius: 50%;
  transition: all ease-out 0.2s;
}

.move_check.off {
  background-color: #222222;
}

.move_check.off:after {
  right: 30px;
}

.move_check.disable {
  background-color: #C8C8C8;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* bottom */
.bottom_wrap {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 800px;
  background-color: #FFFFFF;
  box-shadow: 0px -3px 20px rgb(10 55 65 / 5%);
}

.bottom_wrap .inner {
  display: block;
  max-width: 500px;
  width: 80%;
  margin: 0px auto;
}

.bottom_wrap .inner .svg_home,
.bottom_wrap .inner .svg_score {
  float: left;
  width: 50%;
  height: 56px;
  background-size: auto 42px;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* popup */
.popup {
  position: fixed;
  top: -100vh;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  max-width: 800px;
  height: 0vh;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

.popup.show {
  top: 0px;
  height: 100%;
  opacity: 1;
  z-index: 10;
}

.popup.show:before {
  position: absolute;
  content: '';
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.6;
  pointer-events: auto;
}

.popup .wrap {
  position: absolute;
  left: 0px;
  width: 100%;
  padding: 0px 16px 56px 16px;
  background-color: #FFFFFF;
  transition: all ease-out 0.3s;
  pointer-events: auto;
}

.popup.full .wrap {
  height: 100%;
  background-color: #FAFAFA;
}

.popup.down .wrap {
  bottom: -100%;
}

.popup.down.show .wrap {
  bottom: 0px;
}

.popup .wrap .bottom_wrap {
  display: none;
  height: 56px;
}

.popup .wrap .bottom_wrap button {
  height: 100%;
}

.popup.show .wrap .bottom_wrap {
  display: block;
}

.popup .wrap>.title {
  position: relative;
  width: 100%;
  line-height: 56px;
}

.popup .wrap>.title .btn_wrap {
  position: absolute;
  top: 50%;
  right: 0px;
  width: 32px;
  height: 32px;
}

.popup .wrap>.title .btn_wrap .svg_close {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 6px;
  left: 6px;
}
@media (hover: hover) {
  .popup .wrap>.title .btn_wrap:hover {
    border-radius: 32px;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,.24),
                0 1px 4px   0 rgba(0,0,0,.48) !important;
  }
}

.popup .wrap>.title.max {
  height: 80px;
}

.popup .wrap>.title.max h2 {
  position: absolute;
  top: 40px;
  left: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.popup .wrap>.title.max .btn_wrap {
  right: 16px;
}

.popup.down:not(.analog) .wrap>.title .btn_wrap {
  top: 18px;
}

.popup h2,
.popup h3 {
  font-size: 16px;
  font-weight: 700;
  color: #222222;
}

.popup p {
  color: #444444;
}

.popup em {
  font-weight: 700;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* popup - progress */
.popup .progress_wrap {
  position: absolute;
  top: 50px;
  left: 80px;
  width: calc(100% - 160px);
}

.popup .progress_wrap .bar {
  width: 100%;
  height: 4px;
  background-color: #EEEEEE;
  border-radius: 8px;
}

.popup .progress_wrap .current {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 4px;
  background-color: #FAD84F;
  border-radius: 8px;
  transition: all ease-out 0.5s;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* popup - count */
.popup .count_wrap {
  position: absolute;
  top: 104px;
  left: 50%;
  width: 120px;
  height: 48px;
  margin-left: -60px;
  background-color: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(10, 55, 65, 0.05);
  border-radius: 4px;
}

.popup .count_wrap p {
  font-size: 20px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
}

.popup .count_wrap.time_over {
  width: 148px;
  margin-left: -74px;
}

.popup .count_wrap.time_over p {
  display: none;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* popup - word */
.popup:not(.down) .word_wrap {
  position: absolute;
  top: 152px;
  left: 16px;
  width: calc(100% - 32px);
  height: calc(100% - 210px - 50px);
  padding-top: 20%;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
}

.popup:not(.down) .word_wrap>em {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  color: #222222;
  margin-bottom: 20px;
}

.popup:not(.down) .word_wrap>p {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #444444;
}

.popup:not(.down) .word_wrap .mean_layer {
  position: relative;
  opacity: 0;
  margin-top: 45px;
  width: 100%;
  max-width: 600px;
  padding: 16px;
  background-color: #FFFFFF;
  box-shadow: 0px 16px 30px rgba(10, 55, 65, 0.08);
  border-radius: 4px;
  transition: all ease-out 0.5s;
}

.popup:not(.down) .word_wrap .mean_layer.show {
  opacity: 1;
  margin-top: 35px;
}

.popup:not(.down) .word_wrap .mean_layer [class*="m_ico"] {
  position: absolute;
  top: 16px;
  left: 16px;
}

.popup:not(.down) .word_wrap .mean_layer em {
  display: inline-block;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 10px;
  font-weight: 400;
  color: #222222;
}

.popup:not(.down) .word_wrap .mean_layer p {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #888888;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* 암기 */
.popup.memorize .wrap>.title.max .btn_wrap {
  right: 0px;
}

.loading {
  width:100%;
  max-width: 100%;
  height: 100%;
  font-size: 18px;  
  padding: 10% 10% 0% 10%;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* 암기 : 결과 */
.popup.memorize.result .wrap {
  padding: 0px 0px 56px 0px;
  background-color: #FFFFFF;
}

.popup.memorize.result .wrap>.title.max .btn_wrap {
  right: 16px;
}

.popup.memorize.result .wrap .memorize_result {
  margin: 0px 16px;
  padding-top: 20px;
  padding-bottom: 30px;
}

.popup.memorize.result .wrap .header {
  border-bottom: 1px solid #EEEEEE;
}

.popup.memorize.result .wrap .inner {
  width: 100%;
  height: calc(100% - 82px);
  overflow: hidden;
  overflow-y: auto;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* chart_box */
.chart_box {
  position: relative;
  display: block;
  width: calc(100% - 40px);
  max-width: 800px;
  height: 40vh;
  max-height: 240px;
  margin: 25px auto 70px;
}

.chart_box .chart_line {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.chart_box .chart_line div {
  position: absolute;
  left: 0px;
  margin-top: -7px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #cccccc;
  text-align: left;
}

.chart_box .chart_line div span {
  display: inline-block;
  width: 32px;
  font-weight: 400;
  text-align: right;
}

.chart_box .chart_line div:before {
  position: absolute;
  content: '';
  left: 48px;
  top: 7px;
  width: calc(100% - 48px);
  height: 1px;
  border-top: 1px solid #F5F5F5;
}

.chart_box .chart_line div:nth-child(1) {
  top: 0px;
}

.chart_box .chart_line div:nth-child(2) {
  top: 10%;
}

.chart_box .chart_line div:nth-child(3) {
  top: 20%;
}

.chart_box .chart_line div:nth-child(4) {
  top: 30%;
}

.chart_box .chart_line div:nth-child(5) {
  top: 40%;
}

.chart_box .chart_line div:nth-child(6) {
  top: 50%;
}

.chart_box .chart_line div:nth-child(7) {
  top: 60%;
}

.chart_box .chart_line div:nth-child(8) {
  top: 70%;
}

.chart_box .chart_line div:nth-child(9) {
  top: 80%;
}

.chart_box .chart_line div:nth-child(10) {
  top: 90%;
}

.chart_box .chart_line div:nth-child(11) {
  top: 100%;
}

.chart_box .chart_value,
.chart_box .chart_canvas {
  position: absolute;
  top: 0px;
  left: 48px;
  width: calc(100% - 48px);
  height: 100%;
}

.chart_box .chart_value>div {
  position: relative;
  width: calc(100% / 6);
  height: 100%;
  float: left;
}

.chart_box .chart_value>div:before {
  position: absolute;
  content: '';
  left: 50%;
  top: 0px;
  width: 20%;
  max-width: 40px;
  height: 100%;
  background-color: #EEEEEE;
  transform: translate(-50%, 0px);
}

.chart_box .chart_value p {
  position: absolute;
  left: 0px;
  bottom: -24px;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  color: #222222;
  text-align: center;
}

.chart_box .chart_txt {
  position: absolute;
  bottom: -44px;
  right: 0px;
}

.chart_box .chart_txt div:nth-child(1) {
  float: left;
  margin-right: 18px;
  width: 66px;
  height: 11px;
}

.chart_box .chart_txt div:nth-child(2) {
  float: left;
  width: 79px;
  height: 11px;
}

.chart_box .chart_txt div {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  text-indent: -9999px;
}

.chart_box .chart_value .bar {
  position: absolute;
  left: 50%;
  bottom: 0px;
  width: 20%;
  max-width: 40px;
  background: #fcd848;
  background: -moz-linear-gradient(90deg, #fcd848 0%, #ffc700 100%);
  background: -webkit-linear-gradient(90deg, #fcd848 0%, #ffc700 100%);
  background: linear-gradient(90deg, #fcd848 0%, #ffc700 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcd848', endColorstr='#ffc700', GradientType=1);
  transform: translate(-50%, 0px);
}

.chart_box .chart_value .bar .marker {
  position: absolute;
  display: none;
  top: 0px;
  left: 50%;
  width: 14px;
  height: 14px;
  margin-left: -7px;
  margin-top: -7px;
  background-color: #FCD848;
  border: 4px solid #FFFFFF;
  box-shadow: 0px 4px 16px rgba(10, 55, 65, 0.15);
  border-radius: 50%;
}

.memorize_result .level_box {
  display: block;
  width: 162px;
  height: 48px;
  margin: 0px auto;
  border-radius: 4px;
  border: 1px solid #ffc700;
  font-size: 20px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
}

.memorize_result .level_description {
  display: inline-block;
  width: 100%;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  margin-top: 14px;
}

.memorize_result .level_description span {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 12px;
  color: #666666;
}

.memorize_result .level_description span em {
  font-weight: 500;
  color: #222222;
}

.memorize_result .level_background {
  display: block;
  margin: 20px auto;
  max-width: 302px;
  min-height: 202px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding-top: 140px;
}

.memorize_result .txt_btn {
  display: block;
  margin: 14px auto;
  width: 120px;
  height: 32px;
  line-height: 32px;
  box-shadow: 0px 8px 24px rgba(10, 55, 65, 0.05);
  border-radius: 4px;
}

.memorize_result .unknow_word {
  margin-left: -16px;
  margin-bottom: -56px;
  width: calc(100% + 32px);
  background-color: #F8F8F8;
  padding: 18px 16px;
}

.memorize_result .unknow_word h3 {
  margin-bottom: 12px;
}

.memorize_result .unknow_word .rwbox em {
  font-weight: 500;
  color: #222222;
}

.memorize_result .unknow_word .rwbox p {
  font-size: 12px;
  color: #888888;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* 목표 암기량 */
.popup.amount .inner p {
  font-size: 16px;
  line-height: 24px;
}

.popup.amount .inner .bold {
  font-weight: 700;
}

.popup.amount .inner>div:nth-child(1) {
  margin-top: 20px;
  margin-bottom: 24px;
}

.popup.amount .inner ul {
  margin-bottom: 40px;
}

.popup.amount .inner li {
  margin-bottom: 8px;
}

.popup.amount .wrap>.btn_wrap {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 56px;
}

.popup.amount .wrap>.btn_wrap .txt_btn {
  height: 100%;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* 렉사일 지수란? */
.popup.lexile .wrap {
  padding-bottom: 0px;
}

.popup.lexile .lexile_table {
  display: block;
  width: 100%;
  max-width: 600px;
}

.popup.lexile .lexile_table .row {
  width: 100%;
  height: 45px;
  font-size: 0.6em;
}

@media (min-width: 320px) {
  .popup.lexile .lexile_table .row {
    font-size: 0.8em;
  }
}

@media (min-width: 400px) {
  .popup.lexile .lexile_table .row {
    font-size: 1em;
  }
}

.popup.lexile .lexile_table .row .th {
  float: left;
  width: 33%;
  line-height: 25px;
  color: #888888;
  text-align: center;
}

.popup.lexile .lexile_table .row .td {
  float: left;
  width: 33%;
  font-weight: 500;
  line-height: 45px;
  text-align: center;
  border-top: 1px solid #EEEEEE;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* 학습 */
.popup.word .wrap>.title {
  height: 80px;
}

.popup.study .wrap>.title.max .btn_wrap {
  right: 0px;
}

.popup.study .mean_show {
  position: absolute;
  top: 104px;
  left: 50%;
  width: 100px;
  height: 40px;
  margin-left: -50px;
  background-color: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(10, 55, 65, 0.05);
  border-radius: 4px;
}

.popup.study .mean_show.on {
  width: 110px;
  height: 40px;
  margin-left: -55px;
}

.popup.study .link_box {
  position: absolute;
  bottom: 104px;
  right: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: right;
}

.popup.study .link_box .svg_analog,
.popup.study .link_box .svg_diodict {
  width: 64px;
  height: 64px;
  background-color: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(10, 55, 65, 0.05);
  border-radius: 4px;
  background-position: center center;
}

.popup.study .link_box .svg_analog {
  margin-right: 16px;
}


.popup.study .link_box .source_txt {
  position: absolute;
  bottom: -30px;
  right: 0px;
  font-size: 12px;
  color: #888888;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* 마지막 학습 단어입니다. */
.popup[class*="msg_"] .wrap {
  padding-bottom: 0px;
}

.popup[class*="msg_"] .inner {
  margin-top: -16px;
}

.popup[class*="msg_"] .inner .btn_wrap {
  margin: 24px auto;
}

.popup[class*="msg_"] .inner .txt_btn {
  height: 48px;
  line-height: 48px;
  margin-bottom: 8px;
}

.popup[class*="msg_"] .inner .txt_btn:not(.cbox) {
  background-color: #F8F8F8;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* 유사/반의 */
.popup.analog .wrap {
  height: 100%;
  padding: 0px;
  background-color: #F8F8F8;
}

.popup.analog .wrap>.title.max {
  background-color: #FFFFFF;
}

.popup.analog .wrap .inner {
  width: 100%;
  height: calc(100% - 80px);
  overflow: hidden;
  overflow-y: auto;
  padding: 16px;
}

.popup.analog .word_wrap em {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 13px;
  margin-bottom: 3px;
}

.popup.analog .word_wrap em,
.popup.analog .word_wrap p {
  display: inline-block;
  width: 100%;
}

.popup.analog .word_wrap p:nth-child(3) {
  color: #888888;
  font-size: 12px;
  line-height: 17px;
}

.popup.analog .word_wrap p:nth-child(4) {
  margin-top: 12px;
  line-height: 17px;
}

.popup.analog .word_wrap p:nth-child(5) {
  margin-top: 2px;
  color: #888888;
  font-size: 12px;
  line-height: 17px;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* 퀴즈 */
.quiz_wrap .question {
  position: absolute;
  top: 152px;
  left: 16px;
  width: calc(100% - 32px);
  height: calc(100% - 210px - 50px);
  padding-top: 10%;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}

@media (min-width: 400px) {
    .quiz_wrap .question {
    padding-top: 20%;
  }
}

.quiz_wrap .question p {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  color: #222222;
  margin-bottom: 20px;
}

.write_choice .quiz_wrap .question p,
.mean_choice .quiz_wrap .question p {
  font-size: 24px;
}

@media (min-width: 400px) {
  .write_choice .quiz_wrap .question p,
  .mean_choice .quiz_wrap .question p {
    font-size: 28px;
}
}

.quiz_wrap .choice_box {
  position: absolute;
  bottom: 40px;
  left: 16px;
  width: calc(100% - 32px);
}

.quiz_wrap .choice_box .choice_items {
  position: relative;
  display: block;
  margin: 8px auto;
  width: 100%;
  min-height: 48px;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 8px 24px rgba(10, 55, 65, 0.05);
}

.quiz_wrap .choice_box .choice_items span {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 48px;
  line-height: 18px;
  padding: 0px 56px 0px 16px;
  pointer-events: none;
}

.mean_choice .quiz_wrap .choice_box .choice_items span {
  font-weight: 500;
}

.quiz_wrap .choice_box .choice_items:before {
  position: absolute;
  content: '';
  top: 14px;
  right: 18px;
  width: 20px;
  height: 20px;
  border: 1px solid #dddddd;
  border-radius: 50%;
}

.quiz_wrap .choice_box .choice_items.correct {
  background: #4FA6E0;
  background: -moz-linear-gradient(90deg, #4FA6E0 0%, #6C8ADF 100%);
  background: -webkit-linear-gradient(90deg, #4FA6E0 0%, #6C8ADF 100%);
  background: linear-gradient(90deg, #4FA6E0 0%, #6C8ADF 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4FA6E0', endColorstr='#6C8ADF', GradientType=1);
  color: #FFFFFF;
}

.quiz_wrap .choice_box .choice_items.incorrect {
  background: #DE554F;
  color: #FFFFFF;
}

.quiz_wrap .choice_box .choice_items.answer {
  border: 1px solid #FCD848;
}


.quiz_wrap .choice_box .choice_items.correct:before,
.quiz_wrap .choice_box .choice_items.incorrect:before {
  border-color: #FFFFFF;
}

.quiz_wrap .choice_box .choice_items.answer:before {
  border-color: #FCD848;
}

.quiz_wrap .choice_box .choice_items.correct:after,
.quiz_wrap .choice_box .choice_items.incorrect:after {
  position: absolute;
  content: '';
  top: 21px;
  right: 25px;
  width: 8px;
  height: 8px;
  background-position: center center;
  background-repeat: no-repeat;
}

.quiz_wrap .choice_box.complete .choice_items {
  pointer-events: none;
}

@media (hover: hover) { 
  .choice_items:hover {
      box-shadow: 0 1px 6px 0 rgba(0,0,0,.24),
                  0 1px 4px   0 rgba(0,0,0,.48) !important;
  }
}

.quiz_wrap .drop_box {
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
  margin: 0px auto;
  width: 100%;
  max-width: 800px;
}

.quiz_wrap .drop_box .drop_items {
  width: 26px;
  height: 32px;
  margin: 8px;
  font-size: 20px;
  line-height: 38px;
  border-radius: 4px;
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(10, 55, 65, 0.05);
}

@media (min-width: 400px) {
  .quiz_wrap .drop_box .drop_items {
    width: 32px;
    height: 40px;
  }
}

.quiz_wrap .drag_box {
  position: absolute;
  bottom: 80px;
  left: 16px;
  width: calc(100% - 32px);
  height: 120px;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}

@media (min-height: 800px) {
  .quiz_wrap .drag_box {
    bottom: 120px;
  }
}
.quiz_wrap .drag_box .drag_items,
.quiz_wrap .drop_box .move_items {
  width: 26px;
  height: 32px;
  margin: 6px;
  font-size: 20px;
  line-height: 38px;
  border-radius: 4px;
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(10, 55, 65, 0.05);
}

@media (min-width: 400px) {
  .quiz_wrap .drag_box .drag_items,
  .quiz_wrap .drop_box .move_items {
    width: 32px;
    height: 40px;
    margin: 8px;
  }
}
.quiz_wrap .drop_box .move_items {
  /* position: absolute; */
  /* pointer-events: none; */
  /* margin: 0px; */
  transition: all ease-out 0.5s;
}

.quiz_wrap .drag_box .drag_items.drag {
  pointer-events: none;
  color: #BBBBBB;
  border-color: #EEEEEE;
  background-color: #EEEEEE;
}

.quiz_wrap .drop_box .move_items.incorrect {
  color: #DE554F;
}

.quiz_wrap .replay_wrap {
  position: absolute;
  top: 104px;
  left: 50%;
  width: 120px;
  height: 48px;
  margin-left: -60px;
  background-color: #FFFFFF;
  box-shadow: 0px 8px 24px rgb(10 55 65 / 5%);
  border-radius: 4px;
}

.quiz_wrap .replay_wrap .svg_replay {
  width: 100%;
  height: 100%;
}

.answer_layer {
  position: absolute;
  display: none;
  bottom: 80px;
  left: 50%;
  width: calc(100% - 32px);
  padding: 64px 16px 12px 80px;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(10, 55, 65, 0.05);
  border-radius: 4px;
  transform: translate(-50%, 0%);
  overflow: hidden;
  margin-top: 20px;
  transition: all ease-out 0.3s;
}

.answer_layer.show {
  margin-top: 0px;
}

.answer_layer .correct,
.answer_layer .incorrect {
  width: 100%;
  height: 48px;
}

.answer_layer .correct:before,
.answer_layer .incorrect:before {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 48px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 10C0.5 4.7533 4.7533 0.5 10 0.5C15.2467 0.5 19.5 4.7533 19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.7533 19.5 0.5 15.2467 0.5 10Z' stroke='white' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M13.8536 6.85355C14.0488 6.65829 14.0488 6.34171 13.8536 6.14645C13.6583 5.95118 13.3417 5.95118 13.1464 6.14645L10 9.29289L6.85355 6.14645C6.65829 5.95118 6.34171 5.95118 6.14645 6.14645C5.95118 6.34171 5.95118 6.65829 6.14645 6.85355L9.29289 10L6.14645 13.1464C5.95118 13.3417 5.95118 13.6583 6.14645 13.8536C6.34171 14.0488 6.65829 14.0488 6.85355 13.8536L10 10.7071L13.1464 13.8536C13.3417 14.0488 13.6583 14.0488 13.8536 13.8536C14.0488 13.6583 14.0488 13.3417 13.8536 13.1464L10.7071 10L13.8536 6.85355Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: 18px 14px;
  background-repeat: no-repeat;
  background-size: 20px auto;
  padding-left: 50px;
  line-height: 48px;
  color: #FFFFFF;
}

.answer_layer .correct:before {
  background-color: #4FA6E0;
  content: '정답이에요'
}

.answer_layer .incorrect:before {
  background-color: #DE554F;
  content: '오답이에요'
}

.answer_layer .m_ico_ans {
  position: absolute;
  left: 16px;
  top: 64px;
}

.answer_layer em {
  font-weight: 500;
}

.answer_layer p {
  font-size: 12px;
  color: #888888;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* 내 실력 결과 */
.popup.word.score .wrap {
  padding: 0px 0px 56px 0px;
  background-color: #FFFFFF;
}

.popup.word.score .wrap>.title.max .btn_wrap {
  right: 16px;
}

.popup.word.score .wrap>.inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.popup.word.score .my_chart {
  height: 280px;
}

.popup.word.score .container_has_bottom {
  height: calc(100% - 410px);
}

.popup.word.score .container_no_bottom {
  height: calc(100% - 250px);
}

.popup.word.score .tab_content {
  height: 100%;
  background-color: #FBFBFB;
  padding: 16px;
  overflow: hidden;
  overflow-y: auto;
}

.quizScore {
  margin: 1px;
}

@media (hover: hover) {
  .quizScore:hover {
    box-shadow: 0 1px 6px 0 rgba(0,0,0,.24),
                0 1px 4px   0 rgba(0,0,0,.48) !important;
  }
}
/* ------------------------------------------------------------------------------------------------------------- */
/* 암기 차트 */
.score_chart {
  padding: 20px 16px 0px;
}

.score_chart>div {
  display: block;
  margin: 0px auto 10px;
  padding: 0px 16px;
}

.score_chart .diagram {
  position: relative;
  display: block;
  width: 180px;
  height: 102px;
  margin: 0px auto;
  overflow: hidden;
}

.score_chart .diagram .front:after {
  position: absolute;
  content: '';
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: url('../images/score_background.png') center center / 180px auto no-repeat;
  z-index: 10;
}

.score_chart .diagram .front:before {
  position: absolute;
  content: '';
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: url('../images/score_background_front.png') center center / 180px auto no-repeat;
  z-index: 30;
}

.score_chart .diagram .back {
  position: absolute;
  top: 100%;
  left: -25%;
  width: 150%;
  height: 150%;
  background-color: #FCD848;
  transform-origin: center top;
  z-index: 20;
  transform: rotate(0deg);
  transition: all ease-out 0.3s;
}

.score_chart .diagram .round {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 180px;
  height: 180px;
  transform-origin: center center;
  z-index: 20;
  transform: rotate(0deg);
  transition: all ease-out 0.3s;
}

.score_chart .diagram .round:after {
  position: absolute;
  content: '';
  top: 80px;
  left: -1px;
  width: 20px;
  height: 20px;
  background-color: #FFFFFF;
  border: 3px solid #FCD848;
  border-radius: 50%;
  z-index: 20;
}

.score_chart .diagram p {
  position: absolute;
  top: 64px;
  left: 0px;
  width: 100%;
  text-align: center;
  z-index: 30;
}

.score_chart .diagram p span {
  font-size: 32px;
  font-weight: 700;
}

.score_chart .diagram p:before {
  position: absolute;
  top: -26px;
  left: 0px;
  width: 100%;
  content: '정답';
  font-size: 12px;
  text-align: center;
  color: #888888;
}

.product .score_chart .diagram p:before {
  content: '정답률';
}

.score_chart>div:nth-child(2) {
  text-align: center;
  margin-top: 20px;
}

.score_chart>div:nth-child(2) em {
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
}

.score_chart>div:nth-child(2) p {
  display: block;
  font-size: 12px;
  color: #666666;
  margin-bottom: 10px;
}

.score_chart .recommend {
  margin: 0px auto;
  width: 100%;
  max-width: 800px;
  height: 48px;
  border: 1px solid #EEEEEE;
  border-radius: 4px
}

.score_chart .recommend p {
  position: relative;
  float: left;
  font-weight: 700;
  line-height: 48px;
}

.score_chart .recommend p span {
  font-weight: 400;
  color: #888888;
  margin-right: 20px;
}

.score_chart .recommend p:nth-child(1) {
  width: 30%;
  text-align: center;
}

.score_chart .recommend p:nth-child(1):after {
  position: absolute;
  content: '';
  top: 10px;
  right: 0%;
  width: 1px;
  height: 28px;
  border-right: 1px solid #EEEEEE;
}

.score_chart .recommend p:nth-child(2) {
  width: 70%;
  padding-left: 6%;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* 암기 성과 */
.content.product {
  height: 100%;
}

.product_list .rwbox {
  padding-left: 80px;
}

.product_list .rwbox .state {
  position: absolute;
  top: 50%;
  left: 16px;
  width: 24px;
  height: 24px;
  margin-top: -12px;
}

.product_list .rwbox .number {
  position: absolute;
  top: 50%;
  left: 60px;
  line-height: 20px;
  margin-top: -10px;
  color: #888888;
}

.product_list .rwbox em {
  font-weight: 500;
}

.product_list .rwbox p {
  font-size: 12px;
  color: #888888;
}

.tab_container .tab_content .rwbox_incorrect .preview {
  padding-right: 56px;
}

.product_list .rwbox .nth {
  position: absolute;
  top: 50%;
  right: 16px;
  font-size: 12px;
  color: #DE554F;
  transform: translate(0px, -50%);
}

/* ------------------------------------------------------------------------------------------------------------- */
/* slider_container */
.slider_container {
  width: 100%;
  height: 100%;
}

.slider_container [data-slide-list] {
  display: none;
}

.slider_container .move_slider {
  position: absolute;
  bottom: 80px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider_container .move_slider>div {
  margin: 0px 6px;
  width: 8px;
  height: 8px;
  background-color: #EEEEEE;
  border-radius: 50%;
  cursor: pointer;
}

.slider_container .move_slider>div.on {
  background-color: #FFC700;
}

.popup.intro .wrap {
  position: absolute;
  background: linear-gradient(116.07deg, #FCD848 16.43%, #FFC700 83.57%);
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.popup.intro .wrap .logo_wrap {
  width: 100%;
  height: 30vw;
  top: calc(50% - 20vw);
  margin: 0 auto;
  position: relative;
}
.popup.intro .wrap .logo_wrap .app_logo {
  width: 30vw;
  height: 30vw;
  margin: 0 auto;
  display: block;
}

.popup.intro .wrap .logo_wrap .intro_logo {
  width: 30vw;
  height: 10vw;
  margin: 0 auto;
  display: block;
}
/* ------------------------------------------------------------------------------------------------------------- */
/* guide */
.popup.guide .wrap {
  padding-right: 0px;
}

.popup.guide .wrap>.title .btn_wrap {
  right: 16px;
}

.popup.guide .inner {
  padding-top: 40px;
  height: 100%;
}

.popup.guide .wrap>.title .btn_wrap {
  top: 42px;
}

.popup.guide .inner [data-slide-list] {
  width: 100%;
  height: calc(100% - 50px);
  padding-right: 16px;
  overflow: hidden;
  overflow-y: auto;
}

.popup.guide .svg_guide_memorize1 {
  display: block;
  margin: 0px auto -20px;
  width: 180px;
  height: 180px;
}

.popup.guide .guide_memorize {
  display: block;
  width: 100%;
  max-width: 600px;
  margin: 0px auto;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  padding: 10px 16px;
}

.popup.guide .guide_memorize li {
  position: relative;
  padding: 0px 0px 0px 40px;
}

.popup.guide .guide_memorize li span {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  font-size: 12px;
  line-height: 18px;
}

.popup.guide .guide_memorize li:before {
  position: absolute;
  top: 50%;
  left: 0px;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  content: '';
}

.popup.guide .inlineFlex {
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.popup.guide .inner [data-slide-list]:nth-child(1) .inlineFlex {
  min-height: 510px;
}

.popup.guide .svg_guide_memorize2 {
  display: block;
  margin: 0px auto -20px;
  width: 184px;
  height: 214px;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* 가이드 텍스트 */
.guide_txt {
  display: block;
  margin: 20px auto;
  text-align: center;
}

.guide_txt em {
  font-size: 18px;
  font-weight: 700;
}

.guide_txt p {
  margin-top: 14px;
  color: #888888;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* 암기 성과 없음 */
.none_score {
  position: absolute;
  display: none;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}

.none_score .inlineFlex {
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.none_score .svg_none_score {
  display: block;
  width: 199px;
  height: 190px;
  margin: 0px auto;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* percent */
.percent {
  display: block;
  width: 100%;
  margin: 10px auto 0px;
  max-width: 800px;
  height: 76px;
  border: 1px solid #FCD848;
  border-radius: 8px;
}

.percent .cell {
  float: left;
  width: 33%;
  padding: 6px 0px;
}

.percent .cell p {
  text-align: center;
}

.percent .cell p:nth-child(1) {
  color: #888888;
  line-height: 30px;
  margin: 0px;
}

.percent .cell p:nth-child(2) {
  font-size: 16px;
  font-weight: 500;
  color: #222222;
  line-height: 30px;
  margin: 0px;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* history_wrap */
.history_wrap {
  display: inline-block;
  width: 100%;
  height: calc(100% - 234px);
  padding: 18px 16px;
  border-top: 10px solid #FAFAFA;
  overflow: hidden;
  overflow-y: auto;
  ;
}

.history_wrap .history_th {
  width: 100%;
  height: 45px;
  margin-top: 12px;
  border-bottom: 1px solid #EEEEEE;
}

.history_wrap .history_th p {
  float: left;
  width: 33%;
  color: #888888;
  line-height: 45px;
  text-align: center;
}

.history_wrap .history_list li p {
  float: left;
  width: 33%;
  font-weight: 500;
  line-height: 45px;
  text-align: center;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* coach mark */
.coachmark {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: calc(100% - 56px);
  background-color: rgba(0, 0, 0, 0.6);
  pointer-events: auto;
}

.coachmark_wrap {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.coachmark_wrap .svg_coach1 {
  position: absolute;
  left: 72px;
  top: 64px;
  width: 38px;
  height: 45px;
}

.coachmark_wrap .svg_coach2 {
  position: absolute;
  left: 50%;
  top: 170px;
  width: 37px;
  height: 45px;
  margin-left: -18px;
}

.coachmark_wrap .svg_coach3 {
  position: absolute;
  left: 50%;
  bottom: 70px;
  width: 180px;
  height: 63px;
  margin-left: -90px;
}

.coachmark_wrap .coach_progress {
  position: absolute;
  top: 50px;
  left: 80px;
  width: 50px;
  height: 4px;
  background-color: #FAD84F;
  border-radius: 8px;
}

.coachmark_wrap .btn_wrap {
  position: absolute;
  bottom: 180px;
  left: 16px;
  width: calc(100% - 32px);
  height: 48px;
}

.coachmark_wrap .btn_wrap .txt_btn {
  height: 48px;
  line-height: 48px;
  border-radius: 4px;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* setting */
.idx_setting .container {
  height: calc(100% - 80px);
}

.setting {
  padding: 24px 16px;
}

.setting .division {
  display: inline-block;
  width: 100%;
  margin-bottom: 40px;
}
.setting .division.last {
  margin-bottom: 0px;
}

.setting .division h2 {
  font-size: 16px;
}

.setting .division h2+.row {
  margin-top: 20px;
}

.setting .division .row {
  position: relative;
  margin-top: 14px;
  text-align: right;
}

.setting .secession-btn {
  position: relative;
  margin-top: 14px;
  text-align: right;
  margin-top: 32px;
  padding: 0px 8px;
  text-decoration: underline;
  color: #888888;
}
.setting .division .tit {
  position: absolute;
  left: 0px;
  top: 0px;
  line-height: 32px;
}

.setting .txt_btn {
  padding: 0px 8px;
  height: 32px;
  border-radius: 4px;
}

@media (min-width: 360px) {
  .setting .txt_btn {
    padding: 0px 16px;
  }
}

.setting .profile {
  width: 100%;
  position: relative;
}

.setting .profile-info {
  position: relative;
  padding-left: 54px;
  padding-right: 10px;
  top: 14px;
  text-align: left;
  width: 100%;
}

.setting .sub-title {
  width: 100%;
  font-weight: 700;
  margin-right: 5px;
  word-break: keep-all;
}

.setting .sub-content {
  width: 100%;
  word-break: break-all;
}

.setting .logout-btn{
  margin-top: 32px;
}

.setting .sns-thumbnail {
  width: 36px;
  left: 0px;
  padding-top: 14px;
}
.popup.msg_allow .inner {
  margin-top: 32px;
} 
/* ------------------------------------------------------------------------------------------------------------- */
/* leitner */
.leitner .inner ul {
  margin: 10px 0px;
}

.leitner .inner ul li {
  position: relative;
  padding-left: 16px;
}

.leitner .inner ul li span {
  position: absolute;
  top: 0px;
  left: 0px;
  font-weight: 700;
}

.leitner .inner p:last-child {
  font-weight: 500;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* Open Source License */
.popup.license .wrap {
  padding-bottom: 10px;
}

.license .inner p {
  font-weight: 500;
}

.license .inner ul {
  position: relative;
  margin: 0px -16px 0px -16px;
  width: calc(100% + 32px);
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px 30px;
}

.license .inner ul li p {
  position: relative;
  padding-left: 20px;
}

.license .inner ul li p:first-child {
  padding-left: 0px;
}

.license .inner ul li p:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 10px;
  width: 4px;
  height: 4px;
  margin-top: -2px;
  border-radius: 50%;
  border: 1px solid #888888;
}

.license .inner ul li p:first-child:before {
  display: none;
}

.license .inner ul li+li {
  margin-top: 10px;
}

.license .inner ul li a {
  position: relative;
  left: 20px;
  word-break: break-word;
  text-decoration: underline;
  color: #0000ff;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* TTS play button */
.play_btn {
  margin-left: 12px;
  margin-right: -36px;
  width: 36px;
  height: 36px;
  display: inline-block;
  border-radius: 100%;
  background-color: #FAD84F;
}

/* ------------------------------------------------------------------------------------------------------------- */
/* login */
.login-wrap {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  background: linear-gradient(116.07deg, #FCD848 16.43%, #FFC700 83.57%);
}

.login-html {
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 100px 40px 50px 40px;
}

.login-title {
  color: #222222;
  padding: 90px 40px 50px 40px;
  font-family: 'Noto Sans KR';
  line-height: 24px;
  font-size: 18px;
}

.login-wrap .logo_wrap .svg_logo {
  width: 85px;
  height: 13px;
  margin: 0 auto;
}

.login_description_box {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
}

.login_description {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  border-radius: 4px;
  display: block;
}

.app_logo {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100px;
}

.login-form {
  min-height: 345px;
  position: relative;
  perspective: 1000px;
}

.login-form .group {
  margin-bottom: 15px;
}

.login-form .group .label {
  width: 100%;
  display: block;
}

.login-form .group .input {
  width: 100%;
  display: block;
  border: none;
  padding: 15px 20px;
  border-radius: 25px;
}

.login-form .group .button {
  margin-top: 35px;
  width: 100%;
  display: block;
  border: none;
  padding: 15px 20px;
  border-radius: 25px;
  background-color: #FAD84F;
  color: #ffffff;
}

.login-form .group input[data-type="password"] {
  -webkit-text-security: circle;
}

.foot-lnk {
  text-align: center;
}
@media (hover: hover) {
  .btn:hover::after {
    background: hsla(0, 0, 0, 0.1);
    box-shadow: inset 0 3px 0 hsla(0, 0, 0, 0.2);
  }
}

.btn-social {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  display: block;
  margin-bottom: 15px;
}

.btn-social-group {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.btn-social-button {
  margin: 0 auto;
  text-align: center;
  position: relative;
}

#kakao-login-btn,
#naverIdLogin_loginButton {
  height: 40px;
  display: inline-block;
}

.signup {
  margin-top: 30px;
  position: relative;
  z-index: 1;
  background: hsl(0, 0, 100);
  border-radius: 10px;
  box-shadow: 0 3px 25px hsla(0, 0, 0, 0.2);
}

img#kakao-login-btn {
  width: 180px;
  height: 40px;
}

#naverIdLogin_loginButton img {
  width: 180px;
  height: 40px;
}

.firebaseui-idp-button {
  width: 180px !important;
  height: 40px !important;
  padding: 8px !important;
}

.firebaseui-idp-text {
  padding-left: 8px !important;
}
